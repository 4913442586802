<div class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection">
        <span>Enter One-Time Passcode (OTP)</span>
      </div>
      <form [formGroup]="passcodeForm" [ngClass]="{ 'was-validated': submitted }"
          (ngSubmit)="onSubmit(passcodeForm.value)" novalidate >
      <div class="form">
        <div class="textExplanation col10">Your OTP has been sent to {{targetSelected}}.
          <span class="split-text">Please retrieve it and enter below.</span>
        </div>
        <div class="form-row masked-text" [ngClass]="{ 'has-error': f.submitted  && f.passcode.errors }">
          <div class="col-4">
            <label for="passcode">Enter One-Time Passcode</label>
            <input type="text" class="form-control passcode-text" formControlName="passcode" id="passcode"
                   name="passcode" maxlength="6" tabindex="0" #passcode required
                   autocomplete="off" inputmode="numeric" pattern="[0-9]*" appNumbersOnly>
            <div class="invalid-feedback" id="one-time-passcode-form">
              <div class="error-message">One-Time Passcode is required</div>
            </div>
          </div>
        </div>
        <div class="form-row error-message" *ngIf="displayOTPErrorMessage">The One-Time Passcode (OTP) you entered is
          incorrect or has expired.
          <span>Please check the OTP and try again or request another code.</span>
          <span>NOTE: the user ID will be locked after 3 unsuccessful attempts.</span>
        </div>

        <div class="custom-control-text last-row">
          <span>The code expires in 15 minutes.<br>
          Didn't get the code? <span style="color: rgb(217, 61, 0);">Please wait a few minutes before clicking Resend Code. Some email and text providers may take up to 1-2 minutes to deliver your passcode. </span><br>
          
          <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-resend" tabindex="1"
              href="javascript:void(0);" (click)="getCode()">Resend Code</a> | <span class="split-text-oppo">
              <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-another-delivery" tabindex="2"
                 href="javascript:void(0);" (click)="selectMode()">Select Another Delivery Method</a></span></span>
        </div>

        <div class="form-row error-message resend-otp-error" *ngIf="displayOTPFailedErrorMessage">
          <span>Resend code failed, please try again.</span>
        </div>

        <!-- <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" cbdata-type="button" cbdata-reason="otp-continue" tabindex="5"
                  name="btnLogin" id="btnLogin" #btnLogin type="submit" [disabled]="loading" value="Continue" aria-label="Continue">Continue</button>
           <button tabindex="6" class="btn btn-secondary cbensightenevent"
                cbdata-type="button" cbdata-reason="otp-cancel" name="btnBack" id="btnBack"
                (click)="back($event)" value="Cancel" aria-label="Cancel">Cancel</button>
        </div> -->
        <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" name="btnContinue" id="btnContinue" #btnContinue type="submit" tabindex="3"
                  cbdata-type="button" cbdata-reason="verification-continue" [disabled]="loading" value="Continue" aria-label="Continue">Continue</button>
          <button class="btn btn-secondary cbensightenevent"  name="btnBack" id="btnBack" (click)="back($event)"
                  cbdata-type="button" cbdata-reason="verification-cancel" value="Cancel" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </form>
      <div class="need-assistance">
        Need Assistance?
      </div>
      <!--    Desktop devices  -- Does not contain telephone anchors -->
      <div class="contactArea d-none d-lg-block">If you didn't receive a One-Time Passcode (OTP), please
        contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }}&#32;{{ contactMessage.phone2 }}
        or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-contact-nonmobile-email"
        target="_blank" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--    Does contain telephone anchors for ios mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="!isAndroid">If you didn't receive a One-Time Passcode (OTP), please
        contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-contact-mobile-phone1" target="_blank"
              href="tel:{{ phoneNumber1 }}">{{ contactMessage.phone1 }}</a> &nbsp;
         <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-contact-mobile-phone2" target="_blank"
            href="tel:{{ phoneNumber2 }}">{{ contactMessage.phone2 }}</a>
        or email us at <a  class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-contact-mobile-email"
       target="_blank" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--   Plain text for Android mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="isAndroid" >If you didn't receive a One-Time Passcode (OTP), please
          contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
          at {{ contactMessage.phone1 }}&#32;{{ contactMessage.phone2 }}
          or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
      </div>
    </div>

  </div>

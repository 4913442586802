import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { CustomValidators } from 'src/app/shared/Util/custom-validators';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { universalErrorCodes } from '../../../transmit/universal/universal-error-codes.config';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { assetURL, environment } from 'src/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements AfterViewInit {

  resetPasscodeForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  companyID: string;
  userId: string;
  pageName = 'reset';
  passwordsDoNotMatch = false;
  passwordPolicyNotMet = false;
  specialCharacterNotMet = false;
  specialCharacterNotMetConfirmPass = false;

  @ViewChild('passtext') passwordField: ElementRef;

  public onSubmitPassword: (passtext: string) => void;
  public onCancelPasswordReset: () => void;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;
  public universalLoginServiceCase = environment.universalLoginServiceCase;
  public universalLoginQuickGuide = environment.universalLoginQuickGuide;
  private customerId: '';
  analyticsSessionId = '';
  analyticsUserId = '';
  isAuthenticated = 'n';

  get assetURL() {
    return assetURL;
  }

  constructor(private fb: UntypedFormBuilder, private router: Router,
              private alertService: AlertService,
              private utils: SsoUtils,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService,
              private transmitUtils: UniversalTransmitUtils) {
    const data = this.sessionService.getSessionData(this.pageName);
    this.pageName = data.pageName;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    this.resetPasscodeForm = this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
  }

  get f() {
    return this.resetPasscodeForm.controls;
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()) {
      this.passwordField.nativeElement.focus();
    }
    this.resetAnalytics();
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'resetpass',
      cbaction: 'start',
      cbpgFunction: 'resetpassword',
      cbpgSubFunction: '',
      cbpgName: 'start',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  createForm(): UntypedFormGroup {
    this.resetPasscodeForm = this.fb.group({
      passtext: ['', [Validators.required,
        CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[a-z].*/i, {hasOneAlpha: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        Validators.minLength(8), Validators.maxLength(24)]
      ],
      confirmPassword: ['', [Validators.required]]
    });

    return this.resetPasscodeForm;
  }

  checkSpecialCharForPassword(event: any){
    this.specialCharacterNotMet = this.checkSpecialChar(event);
  }

  onSubmit(formData) {
    this.submitted = true;
    this.alertService.hide();

    if (this.resetPasscodeForm.invalid) {
      if (this.resetPasscodeForm.controls.passtext.value !== '' &&
          !CustomValidators.universalStrongPassword(this.resetPasscodeForm.controls.passtext).strong) {
        this.passwordPolicyNotMet = true;
      }
      return;
    } else if (formData.passtext !== formData.confirmPassword) {
      this.passwordsDoNotMatch = true;
      return;
    }
    this.onSubmitPassword(formData.passtext);
  }

  hidePasswordPolicyErrors(event) {
    if(event.which !== 13){
      this.passwordPolicyNotMet = false;
      this.passwordsDoNotMatch = false;
    }
  }

  checkSpecialChar(event: any){
    const re = new RegExp(/["',-./:;<=>?\s\[\]\\`{|}]/);
    return event.target.value.match(re) ? true : false;
  }

  checkSpecialCharForConfirmPassword(event: any){
    this.specialCharacterNotMetConfirmPass = this.checkSpecialChar(event);
  }

  back(evt) {
    evt.preventDefault();
    // this.router.navigate([`login/${this.appName}`]);
    this.onCancelPasswordReset();
  }

  public showTransmitErrorMessage(errorCode: string) {
    const universalId = sessionStorage.getItem('universalId');
    let errorMessage = universalErrorCodes[errorCode];
    if (errorMessage) {
      errorMessage = this.transmitUtils.replaceParams(errorMessage, this.transmitUtils.getContactMessageParamsMap());
      datadogLogs.logger.error(`operation=reset password; universalId=${universalId}; msgtxt=${errorCode}`);
      this.alertService.error(errorMessage);
    } else {
      this.sessionService.setErrorDescription({description: 'Transmit is unavailable', code: errorCode});
      datadogLogs.logger.error(`operation=reset password; universalId=${universalId}; msgtxt=${errorCode}`);
      this.router.navigate(['unavailable']);
    }
  }

}
